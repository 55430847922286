import { faClone } from '@fortawesome/free-regular-svg-icons';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Big from 'big.js';
import { Link } from 'found';
import { Duration } from 'luxon';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { createFragmentContainer, graphql } from 'react-relay';
import {
  Button, Card, CardBody, CardFooter, CardHeader, Form, Modal, ModalBody, ModalFooter, ModalHeader,
} from 'reactstrap';

import Loading from 'src/components/Loading';
import { ClauseEdit, ClauseShow } from 'src/components/TimeOfUse';
import { APIConfig } from 'src/config';
import ProposeCommunityTradeMutation from 'src/mutations/ProposeCommunityTradeMutation';
import FlashesStore from 'src/stores/FlashesStore';
import {
  DAY_PUBLIC_HOLIDAY, Days, Months, TRADE_DIRECTION_BUY, TRADE_DIRECTION_SELL,
} from 'src/util/constants';
import { timeOfDayToDuration } from 'src/util/timeOfUse';

class TradeRuleSetCommunityTimeOfUseForm extends React.Component {
  static mutationName = 'proposeCommunityTrade';

  /**
   * @param {string} tradeDirection (either TRADE_DIRECTION_BUY or TRADE_DIRECTION_SELL)
   * @returns {Function} the is valid price function for a direction.
   */
  static isValidPriceFunc(tradeDirection) {
    switch (tradeDirection) {
      case TRADE_DIRECTION_BUY:
        return TradeRuleSetCommunityTimeOfUseForm.isValidBuyPrice;
      case TRADE_DIRECTION_SELL:
        return TradeRuleSetCommunityTimeOfUseForm.isValidSellPrice;
      default:
        return ((_) => (false));
    }
  }

  /**
   * Validate a buy price.
   * @param {(Big|number)} price
   * @returns {boolean} True if valid.
   */
  static isValidBuyPrice(price) {
    const { COMMUNITY_BUY_PRICE_MAX, COMMUNITY_BUY_PRICE_MIN } = APIConfig();

    if (COMMUNITY_BUY_PRICE_MIN !== undefined && price < COMMUNITY_BUY_PRICE_MIN) {
      return false;
    }
    if (COMMUNITY_BUY_PRICE_MAX !== undefined && price > COMMUNITY_BUY_PRICE_MAX) {
      return false;
    }

    return true;
  }

  /**
   * Validate a sell price.
   * @param {(Big|number)} price
   * @returns {boolean} True if valid.
   */
  static isValidSellPrice(price) {
    const { COMMUNITY_SELL_PRICE_MAX, COMMUNITY_SELL_PRICE_MIN } = APIConfig();

    if (COMMUNITY_SELL_PRICE_MIN !== undefined && price < COMMUNITY_SELL_PRICE_MIN) {
      return false;
    }
    if (COMMUNITY_SELL_PRICE_MAX !== undefined && price > COMMUNITY_SELL_PRICE_MAX) {
      return false;
    }

    return true;
  }

  /**
   * For the trade direction, return the price input help text.
   * @param {import('react-intl').IntlShape} intl
   * @param {string} tradeDirection
   * @returns {(string|Element|(string|Element)[]|null)} help text.
   */
  static priceHelpText(intl, tradeDirection) {
    const energyPriceUnitAbbrTitle = intl.formatMessage({
      id: 'trade_rule.trade_rule_set_community_time_of_use.trade_rule_set_community_time_of_use_form.energy_cost.abbr.title',
      defaultMessage: 'cents per kilowatt hour',
    });
    const energyPriceUnitAbbrLabel = intl.formatMessage({
      id: 'trade_rule.trade_rule_set_community_time_of_use.trade_rule_set_community_time_of_use_form.energy_cost.abbr.label',
      defaultMessage: 'c/kWh',
    });
    const energyPriceUnit = (
      <abbr
        title={energyPriceUnitAbbrTitle}
      >
        {energyPriceUnitAbbrLabel}
      </abbr>
    );

    switch (tradeDirection) {
      case TRADE_DIRECTION_BUY:
        return intl.formatMessage(
          {
            id: 'trade_rule.trade_rule_set_community_time_of_use.trade_rule_set_community_time_of_use_form.min_price.help_text',
            defaultMessage: 'What is the maximum price, in {energyPriceUnit}, that you will buy your energy from the community?',
          },
          { energyPriceUnit },
        );
      case TRADE_DIRECTION_SELL:
        return intl.formatMessage(
          {
            id: 'trade_rule.trade_rule_set_community_time_of_use.trade_rule_set_community_time_of_use_form.max_price.help_text',
            defaultMessage: 'What is the minimum price, in {energyPriceUnit}, that you will sell your energy to the community?',
          },
          { energyPriceUnit },
        );
      default:
        return null;
    }
  }

  /**
   * Return the price label for the direction.
   * @param {import('react-intl').IntlShape} intl
   * @param {string} tradeDirection
   * @returns {(string|Element|(string|Element)[]|null)} the label to use for the price.
   */
  static priceLabel(intl, tradeDirection) {
    switch (tradeDirection) {
      case TRADE_DIRECTION_BUY:
        return intl.formatMessage({
          id: 'trade_rule.trade_rule_set_community_time_of_use.trade_rule_set_community_time_of_use_form.max_buy_price.label',
          defaultMessage: 'Maximum buy price',
        });
      case TRADE_DIRECTION_SELL:
        return intl.formatMessage({
          id: 'trade_rule.trade_rule_set_community_time_of_use.trade_rule_set_community_time_of_use_form.min_sell_price.label',
          defaultMessage: 'Minimum sell price',
        });
      default:
        return null;
    }
  }

  constructor(props) {
    super(props);

    const { meter } = this.props;
    const { tradePointId, communityRules } = meter;

    this.lastClauseId = 0;

    let buyClauses = [];
    const currentBuyRules = communityRules.edges
      .filter((edge) => edge.node.buyer.tradePoint.uuid === tradePointId);
    if (currentBuyRules.length === 1) {
      buyClauses = currentBuyRules[0].node.clauses.edges.map(this.formatClauseFromEdge);
    }

    let sellClauses = [];
    const currentSellRules = communityRules.edges
      .filter((edge) => edge.node.seller.tradePoint.uuid === tradePointId);
    if (currentSellRules.length === 1) {
      sellClauses = currentSellRules[0].node.clauses.edges.map(this.formatClauseFromEdge);
    }

    let ignorePublicHolidays = false;
    if (communityRules.edges.length > 0) {
      const rule = communityRules.edges[0].node;
      if (rule.clauses.edges.length > 0) {
        const clause = rule.clauses.edges[0].node;
        if (clause.ignorePublicHolidays) {
          ignorePublicHolidays = true;
        }
      }
    }

    this.state = {
      buyClauses,
      sellClauses,
      ignorePublicHolidays,
      editing: null, // { direction, index }
      processing: false,
      showConfirmCoverage: false,
    };
  }

  generateClauseId = () => {
    this.lastClauseId += 1;
    return this.lastClauseId.toString();
  };

  formatClauseFromEdge = (edge) => {
    const { node } = edge;
    const {
      price, monthsOfYear, daysOfWeek, timesOfDay,
    } = node;

    return {
      price,
      monthsOfYear,
      daysOfWeek,
      timesOfDay,
      id: this.generateClauseId(),
    };
  };

  copyFromResidual = () => {
    const { meter } = this.props;
    const { tradePointId, residualRules } = meter;

    let buyClauses = [];
    const currentBuyRules = residualRules.edges
      .filter((edge) => edge.node.buyer.tradePoint.uuid === tradePointId);
    if (currentBuyRules.length === 1) {
      buyClauses = currentBuyRules[0].node.clauses.edges.map(this.formatClauseFromEdge);
    }

    let sellClauses = [];
    const currentSellRules = residualRules.edges
      .filter((edge) => edge.node.seller.tradePoint.uuid === tradePointId);
    if (currentSellRules.length === 1) {
      sellClauses = currentSellRules[0].node.clauses.edges.map(this.formatClauseFromEdge);
    }

    if (buyClauses.length === 0 && sellClauses.length === 0) {
      return null;
    }

    let ignorePublicHolidays = false;
    if (residualRules.edges.length > 0) {
      const rule = residualRules.edges[0].node;
      if (rule.clauses.edges.length > 0) {
        const clause = rule.clauses.edges[0].node;
        if (clause.ignorePublicHolidays) {
          ignorePublicHolidays = true;
        }
      }
    }

    return { buyClauses, sellClauses, ignorePublicHolidays };
  };

  handleSubmit = (event) => {
    event.preventDefault();

    const { intl, property, meter } = this.props;
    const { timezone, publicHolidayRegion } = property;
    const { tradePointId } = meter;
    const {
      buyClauses, sellClauses, ignorePublicHolidays, processing, showConfirmCoverage,
    } = this.state;

    if (processing) {
      FlashesStore.flash(
        FlashesStore.INFO,
        intl.formatMessage({
          id: 'trade_rule.trade_rule_set_community_time_of_use.trade_rule_set_community_time_of_use_form.state.invalid_still_processing', defaultMessage: 'We are still processing your request...',
        }),
        TradeRuleSetCommunityTimeOfUseForm.mutationName,
      );
      return;
    }

    const { valid, coverage } = this.validate();

    if (!valid) {
      FlashesStore.flash(
        FlashesStore.ERROR,
        intl.formatMessage({ id: 'trade_rule.trade_rule_set_community_time_of_use.trade_rule_set_community_time_of_use_form.state.invalid_data', defaultMessage: 'Form data not valid. Please see below.' }),
        TradeRuleSetCommunityTimeOfUseForm.mutationName,
      );
      return;
    }

    if (!showConfirmCoverage && !coverage) {
      this.toggleConfirmCoverage();
      return;
    }

    this.setState({ processing: true });
    FlashesStore.reset();

    const formatClause = (clause) => {
      const {
        price, monthsOfYear, daysOfWeek, timesOfDay,
      } = clause;

      return {
        price,
        timezone,
        publicHolidayRegion,
        ignoreDaylightSavings: false,
        ignorePublicHolidays,
        monthsOfYear,
        daysOfWeek,
        timesOfDay,
      };
    };

    const input = {
      tradePointId,
      buyClauses: buyClauses.map(formatClause),
      sellClauses: sellClauses.map(formatClause),
    };

    ProposeCommunityTradeMutation(
      input,
      this.handleSubmitSuccess,
      this.handleSubmitFailure,
    );
  };

  handleSubmitSuccess = (_response) => {
    const { intl, property, router } = this.props;

    this.setState({ processing: false });

    FlashesStore.flash(FlashesStore.SUCCESS, intl.formatMessage({
      id: 'trade_rule.trade_rule_set_community_time_of_use.trade_rule_set_community_time_of_use_form.response_message.success', defaultMessage: 'Community trade rules have been set',
    }), TradeRuleSetCommunityTimeOfUseForm.mutationName);

    router.push(`/properties/${property.uuid}/trade-rules/active`);
  };

  handleSubmitFailure = (error) => {
    const { showConfirmCoverage } = this.state;

    this.setState({ processing: false });

    FlashesStore.flash(FlashesStore.ERROR, error, TradeRuleSetCommunityTimeOfUseForm.mutationName);

    if (showConfirmCoverage) {
      this.toggleConfirmCoverage();
    }
  };

  isEditing = (direction, index) => {
    const { editing } = this.state;

    if (direction === undefined && index === undefined) {
      return !!editing;
    }

    return editing && editing.direction === direction && editing.index === index;
  };

  editClause = (direction, index) => {
    this.setState({ editing: { direction, index } });
  };

  addClause = (direction) => {
    this.setState({ editing: { direction, index: null } });
  };

  removeClause = (direction, index) => {
    const { buyClauses, sellClauses } = this.state;

    if (direction === TRADE_DIRECTION_BUY) {
      const newBuyClauses = [...buyClauses];
      newBuyClauses.splice(index, 1);
      this.setState({ buyClauses: newBuyClauses });
    }
    if (direction === TRADE_DIRECTION_SELL) {
      const newSellClauses = [...sellClauses];
      newSellClauses.splice(index, 1);
      this.setState({ sellClauses: newSellClauses });
    }
  };

  editClauseSet = (direction, index, newClause) => {
    const { buyClauses, sellClauses } = this.state;

    if (direction === TRADE_DIRECTION_BUY) {
      const newBuyClauses = [...buyClauses];
      newBuyClauses[index] = { ...newClause, id: buyClauses[index].id };
      this.setState({ buyClauses: newBuyClauses, editing: null });
    }
    if (direction === TRADE_DIRECTION_SELL) {
      const newSellClauses = [...sellClauses];
      newSellClauses[index] = { ...newClause, id: sellClauses[index].id };
      this.setState({ sellClauses: newSellClauses, editing: null });
    }
  };

  addClauseSet = (direction, newClause) => {
    const { buyClauses, sellClauses } = this.state;

    if (direction === TRADE_DIRECTION_BUY) {
      const newBuyClauses = [...buyClauses];
      newBuyClauses.push({ ...newClause, id: this.generateClauseId() });
      this.setState({ buyClauses: newBuyClauses, editing: null });
    }
    if (direction === TRADE_DIRECTION_SELL) {
      const newSellClauses = [...sellClauses];
      newSellClauses.push({ ...newClause, id: this.generateClauseId() });
      this.setState({ sellClauses: newSellClauses, editing: null });
    }
  };

  cancelEdit = () => {
    this.setState({ editing: null });
  };

  validate = () => {
    const { buyClauses, sellClauses } = this.state;

    if (buyClauses.length === 0 && sellClauses.length === 0) {
      return { valid: false };
    }

    const { valid: buyValid, coverage: buyCoverage } = this.validateClauses(buyClauses);
    const { valid: sellValid, coverage: sellCoverage } = this.validateClauses(sellClauses);

    if (!buyValid || !sellValid) {
      return { valid: false };
    }
    return { valid: true, coverage: buyCoverage && sellCoverage };
  };

  validateClauses = (clauses) => {
    const { ignorePublicHolidays } = this.state;

    const relevantDays = ignorePublicHolidays
      ? Days.filter((day) => day !== DAY_PUBLIC_HOLIDAY) : Days;

    const combinations = [];
    clauses.forEach(({ monthsOfYear, daysOfWeek, timesOfDay }) => {
      monthsOfYear.forEach((month) => {
        daysOfWeek.forEach((day) => {
          if (ignorePublicHolidays && day === DAY_PUBLIC_HOLIDAY) {
            return;
          }
          timesOfDay.forEach(({ start, finish }) => {
            combinations.push({
              month: Months.indexOf(month),
              day: relevantDays.indexOf(day),
              time: {
                start: timeOfDayToDuration(start).valueOf(),
                finish: timeOfDayToDuration(finish).valueOf(),
              },
            });
          });
        });
      });
    });

    combinations.sort((a, b) => (a.month - b.month || a.day - b.day
      || a.time.start - b.time.start || a.time.finish - b.time.finish));

    if (combinations.length === 0) {
      return { valid: true, coverage: false };
    }

    const endOfDay = Duration.fromObject({ hours: 24 }).valueOf();

    let coverage = true;

    const first = combinations[0];
    if (first.month !== 0 || first.day !== 0 || first.time.start !== 0) {
      coverage = false;
    }

    const last = combinations[combinations.length - 1];
    if (last.month !== Months.length - 1 || last.day !== relevantDays.length - 1
      || last.time.finish !== endOfDay) {
      coverage = false;
    }

    for (let i = 1; i < combinations.length; i += 1) {
      const curr = combinations[i];
      const prev = combinations[i - 1];

      let expectedMonth = prev.month;
      let expectedDay = prev.day;
      let expectedStart = prev.time.finish;
      if (prev.time.finish === endOfDay) {
        expectedStart = 0;
        if (prev.day === relevantDays.length - 1) {
          expectedDay = 0;
          expectedMonth = prev.month + 1;
        } else {
          expectedDay = prev.day + 1;
        }
      }

      const comparison = curr.month - expectedMonth || curr.day - expectedDay
        || curr.time.start - expectedStart;

      if (comparison < 0) {
        return { valid: false };
      }
      if (comparison > 0) {
        coverage = false;
      }
    }

    return { valid: true, coverage };
  };

  toggleConfirmCoverage = () => {
    this.setState(({ showConfirmCoverage }) => ({ showConfirmCoverage: !showConfirmCoverage }));
  };

  showClause = (clause, index, tradeDirection) => {
    const { intl } = this.props;
    if (this.isEditing(tradeDirection, index)) {
      return (
        <ClauseEdit
          clause={clause}
          setClause={
            (newClause) => this.editClauseSet(tradeDirection, index, newClause)
          }
          cancelEdit={this.cancelEdit}
          priceLabel={TradeRuleSetCommunityTimeOfUseForm.priceLabel(intl, tradeDirection)}
          priceHelpText={TradeRuleSetCommunityTimeOfUseForm.priceHelpText(intl, tradeDirection)}
          priceValidation={TradeRuleSetCommunityTimeOfUseForm.isValidPriceFunc(tradeDirection)}
          key={clause.uuid}
        />
      );
    }
    return (
      <ClauseShow
        clause={clause}
        showControls={!this.isEditing()}
        editClause={() => this.editClause(tradeDirection, index)}
        removeClause={() => this.removeClause(tradeDirection, index)}
        key={clause.uuid}
      />
    );
  };

  render() {
    if (this.error) {
      return <div><FormattedMessage id="error.title" defaultMessage="Error!" /></div>;
    }
    if (!this.props) {
      return <Loading />;
    }

    const {
      intl, property, meter, router,
    } = this.props;
    const {
      buyClauses, sellClauses, processing, showConfirmCoverage,
    } = this.state;
    const pageTitle = intl.formatMessage({
      id: 'trade_rule.trade_rule_set_community_time_of_use.trade_rule_set_community_time_of_use_form.page_title',
      defaultMessage: 'Set community trade rules for {meterTitle}',
    }, { meterTitle: meter.title });

    return (
      <Form onSubmit={this.handleSubmit}>
        <Card>
          <CardHeader className="d-flex flex-wrap">
            <h2 className="mb-0">
              {pageTitle}
            </h2>
            <Link to={`/properties/${property.uuid}/meters/${meter.uuid}/trade-rules/community/set`} className="btn btn-darken ms-auto">
              <FormattedMessage id="trade_rule.trade_rule_set_community_time_of_use.trade_rule_set_community_time_of_use_form.flat_pricing.label" defaultMessage=" Flat pricing" />
            </Link>
          </CardHeader>
          <CardBody>
            <div>
              <h3><FormattedMessage id="trade_rule.trade_rule_set_community_time_of_use.trade_rule_set_community_time_of_use_form.header.trade_direction_buy.label" defaultMessage=" Buy" /></h3>
              {buyClauses.map((clause, index) => (
                this.showClause(clause, index, TRADE_DIRECTION_BUY)
              ))}
              {this.isEditing(TRADE_DIRECTION_BUY, null) && (
                <ClauseEdit
                  setClause={(newClause) => this.addClauseSet(TRADE_DIRECTION_BUY, newClause)}
                  cancelEdit={this.cancelEdit}
                  priceLabel={
                    TradeRuleSetCommunityTimeOfUseForm.priceLabel(intl, TRADE_DIRECTION_BUY)
                  }
                  priceHelpText={
                    TradeRuleSetCommunityTimeOfUseForm.priceHelpText(intl, TRADE_DIRECTION_BUY)
                  }
                  priceValidation={TradeRuleSetCommunityTimeOfUseForm.isValidBuyPrice}
                />
              )}
              {!this.isEditing() && (
                <Button color="darken" onClick={() => this.addClause(TRADE_DIRECTION_BUY)}>
                  <FontAwesomeIcon icon={faPlus} className="me-2" />
                  <FormattedMessage id="trade_rule.trade_rule_set_community_time_of_use.trade_rule_set_community_time_of_use_form.trade_direction_buy.add_clause.label" defaultMessage="Add clause" />
                </Button>
              )}
            </div>
            <div className="mt-4">
              <h3><FormattedMessage id="trade_rule.trade_rule_set_community_time_of_use.trade_rule_set_community_time_of_use_form.header.trade_direction_sell.label" defaultMessage="Sell" /></h3>
              {sellClauses.map((clause, index) => (
                this.showClause(clause, index, TRADE_DIRECTION_SELL)
              ))}
              {this.isEditing(TRADE_DIRECTION_SELL, null) && (
                <ClauseEdit
                  setClause={(newClause) => this.addClauseSet(TRADE_DIRECTION_SELL, newClause)}
                  cancelEdit={this.cancelEdit}
                  priceLabel={
                    TradeRuleSetCommunityTimeOfUseForm.priceLabel(intl, TRADE_DIRECTION_SELL)
                  }
                  priceHelpText={
                    TradeRuleSetCommunityTimeOfUseForm.priceHelpText(intl, TRADE_DIRECTION_SELL)
                  }
                  priceValidation={TradeRuleSetCommunityTimeOfUseForm.isValidSellPrice}
                />
              )}
              {!this.isEditing() && (
                <Button color="darken" onClick={() => this.addClause(TRADE_DIRECTION_SELL)}>
                  <FontAwesomeIcon icon={faPlus} className="me-2" />
                  <FormattedMessage id="trade_rule.trade_rule_set_community_time_of_use.trade_rule_set_community_time_of_use_form.trade_direction_sell.add_clause.label" defaultMessage="Add clause" />
                </Button>
              )}
            </div>
          </CardBody>
          <CardFooter className="d-flex">
            <Button color="primary" className="me-2" disabled={processing || this.isEditing()}>
              <FormattedMessage id="trade_rule.trade_rule_set_community_time_of_use.trade_rule_set_community_time_of_use_form.set.label" defaultMessage="Set" />
            </Button>
            <Button color="" onClick={() => (router.go(-1))} disabled={processing || this.isEditing()}>
              <FormattedMessage id="trade_rule.trade_rule_set_community_time_of_use.trade_rule_set_community_time_of_use_form.cancel.label" defaultMessage=" Cancel" />
            </Button>
            <Button
              color="darken"
              className="ms-auto"
              onClick={() => this.setState(this.copyFromResidual())}
              disabled={processing || this.isEditing() || !this.copyFromResidual()}
            >
              <FontAwesomeIcon icon={faClone} className="me-2" />
              <FormattedMessage id="trade_rule.trade_rule_set_community_time_of_use.trade_rule_set_community_time_of_use_form.copy_from_residual.label" defaultMessage="Copy from retailer default" />
            </Button>
          </CardFooter>
        </Card>
        <Modal isOpen={showConfirmCoverage} toggle={this.toggleConfirmCoverage}>
          <ModalHeader toggle={this.toggleConfirmCoverage}>
            <FormattedMessage id="trade_rule.trade_rule_set_community_time_of_use.trade_rule_set_community_time_of_use_form.no_full_coverage.label" defaultMessage="No full time coverage" />
          </ModalHeader>
          <ModalBody>
            <FormattedMessage
              id="trade_rule.trade_rule_set_community_time_of_use.trade_rule_set_community_time_of_use_form.no_full_coverage.help_text"
              defaultMessage="Clauses of proposed community trade rule do not cover all possible times. Confirm that this is okay."
            />

          </ModalBody>
          <ModalFooter>
            <Button color="primary" className="me-2" onClick={this.handleSubmit}>
              <FormattedMessage id="trade_rule.trade_rule_set_community_time_of_use.trade_rule_set_community_time_of_use_form.submit.label" defaultMessage="Confirm" />
            </Button>
            <Button color="" onClick={this.toggleConfirmCoverage}>
              <FormattedMessage id="trade_rule.trade_rule_set_community_time_of_use.trade_rule_set_community_time_of_use_form.continue_editing.label" defaultMessage="Continue editing" />
            </Button>
          </ModalFooter>
        </Modal>
      </Form>
    );
  }
}

TradeRuleSetCommunityTimeOfUseForm.propTypes = {
  intl: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  meter: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  property: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  router: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
};

TradeRuleSetCommunityTimeOfUseForm.defaultProps = {
  property: null,
  meter: null,
};

export default injectIntl(createFragmentContainer(
  TradeRuleSetCommunityTimeOfUseForm,
  {
    property: graphql`
      fragment TradeRuleSetCommunityTimeOfUseForm_property on Property {
        uuid
        timezone
        publicHolidayRegion
      }
    `,
    meter: graphql`
      fragment TradeRuleSetCommunityTimeOfUseForm_meter on Meter {
        uuid
        identifier
        title
        tradePointId
        communityRules: rules(first: 500, type: TRADE_TYPE_COMMUNITY, state: TRADE_RULE_STATE_ACCEPTED) {
          edges {
            node {
              uuid
              priority
              tradeType
              state
              buyer {
                userId
                communityId
                residualId
                tradePoint {
                  uuid
                }
              }
              seller {
                userId
                communityId
                residualId
                tradePoint {
                  uuid
                }
              }
              clauses {
                edges {
                  node {
                    price
                    ignorePublicHolidays
                    monthsOfYear
                    daysOfWeek
                    timesOfDay {
                      start  { hours minutes seconds }
                      finish { hours minutes seconds }
                    }
                  }
                }
              }
            }
          }
        }
        residualRules: rules(first: 500, type: TRADE_TYPE_RESIDUAL, state: TRADE_RULE_STATE_ACCEPTED, start: $now, finish: $now) {
          edges {
            node {
              uuid
              priority
              tradeType
              state
              buyer {
                userId
                communityId
                residualId
                tradePoint {
                  uuid
                }
              }
              seller {
                userId
                communityId
                residualId
                tradePoint {
                  uuid
                }
              }
              clauses {
                edges {
                  node {
                    price
                    ignorePublicHolidays
                    monthsOfYear
                    daysOfWeek
                    timesOfDay {
                      start  { hours minutes seconds }
                      finish { hours minutes seconds }
                    }
                  }
                }
              }
            }
          }
        }
      }
    `,
  },
));

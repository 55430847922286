import { commitMutation, graphql } from 'react-relay';

import environment from 'src/util/environment';

const mutation = graphql`
  mutation RejectNominatedTradeMutation($uuid: ID!) {
    rejectNominatedTrade(uuid: $uuid) {
      id
      uuid
    }
  }
`;

/**
 * Reject nominated trade mutation.
 * @param {object} tradeRuleIdSet id and UUID of the trade rule to be rejected.
 * @param {Function} onCompleted Function to call on success.
 * @param {Function} onError Function to call on error.
 * @param {object} formatMessage i18n object
 */
const RejectNominatedTradeMutation = (tradeRuleIdSet, onCompleted, onError, formatMessage) => {
  const { id, uuid } = tradeRuleIdSet;

  commitMutation(
    environment,
    {
      mutation,
      variables: { uuid },
      onCompleted: (response) => { onCompleted(response, formatMessage); },
      onError,
      updater: (store) => {
        // Get the current trade rule record from the store.
        const ruleProxy = store.get(id);
        ruleProxy.setValue('TRADE_RULE_STATE_REJECTED', 'state');
      },
    },
  );
};

export default RejectNominatedTradeMutation;

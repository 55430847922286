import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useIntl } from 'react-intl';
import { graphql, useFragment } from 'react-relay';

import Loading from 'src/components/Loading';
import { APIConfig } from 'src/config';
import Breadcrumbs from 'src/enosikit/components/Breadcrumbs';
import usePortfolioAddPropertyMutation from 'src/mutations/PortfolioAddPropertyMutation';
import FlashesStore from 'src/stores/FlashesStore';
import isActive from 'src/util/isActive';

import Form from './Form';
import filterProperties from './helpers';

const AdminPortfolioPropertyMemberCreateFragment = graphql`
  fragment AdminPortfolioPropertyMemberCreate_portfolio on Portfolio {
    uuid
    title
    active {start finish}
    propertyMembers {
      edges {
        node {
          uuid
          property {
            uuid
            active {start finish}
          }
        }
      }
    }
  }
`;

const AdminPortfolioPropertyMemberCreatePropertiesFragment = graphql`
fragment AdminPortfolioPropertyMemberCreateProperties_viewer on Viewer
@argumentDefinitions(
  cursor: { type: "String" }
  count: { type: "Int" }
) {
    properties(first: $count, after: $cursor)
      @connection(key: "AdminPropertiesList_properties") {
      edges {
        cursor
        node {
          uuid
          externalIdentifier
          title
          active {
            start
            finish
          }
          address {
             line1
             line2
             city
             state
             postcode
          }
        }
      }
      count
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
    }
  }
`;

/**
 * @param {object} props
 *@returns {React.ReactComponentElement} - AdminPortfolioUserMemberCreate component
 */
function AdminPortfolioPropertyMemberCreate(props) {
  if (!props || Object.keys(props)?.length === 0) {
    return <Loading />;
  }
  const { viewer } = props;
  let { portfolio } = props;
  portfolio = useFragment(AdminPortfolioPropertyMemberCreateFragment, portfolio);
  const { properties } = useFragment(AdminPortfolioPropertyMemberCreatePropertiesFragment, viewer);
  const {
    active, title: portfolioTitle, uuid: portfolioUuid, propertyMembers,
  } = portfolio || {};
  const activeProperties = properties?.edges?.filter(
    (property) => isActive(property?.node?.active),
  );

  const propertiesToChooseFrom = filterProperties(activeProperties, propertyMembers);

  const intl = useIntl();
  const pageTitle = intl.formatMessage({ id: 'admin.admin_portfolio.admin_portfolio_property_member_create.page.title', defaultMessage: 'Enosi Admin - Portfolio property member create' });
  const breadcrumbAdmin = intl.formatMessage({ id: 'admin.admin_portfolio.admin_portfolio_property_member_create.breadcrumbs.admin.label', defaultMessage: 'Admin' });
  const breadcrumbPortfolios = intl.formatMessage({ id: 'admin.admin_portfolio.admin_portfolio_property_member_create.breadcrumbs.portfolios.label', defaultMessage: 'Portfolios' });
  const breadcrumbAddPortfolioPropertyMember = intl.formatMessage({ id: 'admin.admin_portfolio.admin_portfolio_property_member_create.breadcrumbs.portfolio_property_member_add.label', defaultMessage: 'Add portfolio property member' });

  const [processing, setProcessing] = useState(false);
  const { router } = props;
  const mutationName = 'portfolioAddProperty';

  const handlePortfolioPropertyMemberCreateSuccess = (response, errors) => {
    setProcessing(false);

    if (errors && errors.length > 0) {
      errors.forEach((error) => FlashesStore.flash(FlashesStore.ERROR, {
        message: FlashesStore.ERROR,
        source: {
          errors: [error],
        },
      }, mutationName));
      return;
    }

    const { uuid } = response.portfolioAddProperty;

    FlashesStore.flash(
      FlashesStore.SUCCESS,
      <span>
        {intl.formatMessage({ id: 'admin.admin_portfolio.admin_portfolio_property_member_create.response_message.success', defaultMessage: 'Successfully added portfolio property member {uuid}.' }, { uuid })}
      </span>,
      mutationName,
    );

    router.push(`/admin/portfolio-property-members/${uuid}`);
  };

  const handlePortfolioPropertyMemberCreateFailure = (error) => {
    setProcessing(false);

    FlashesStore.flash(FlashesStore.ERROR, error, mutationName);
  };
  const portfolioAddProperty = usePortfolioAddPropertyMutation();

  const handlePortfolioPropertyMemberCreate = (input) => {
    setProcessing(true);
    portfolioAddProperty(
      portfolioUuid,
      input,
      handlePortfolioPropertyMemberCreateSuccess,
      handlePortfolioPropertyMemberCreateFailure,
    );
  };

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{pageTitle}</title>
      </Helmet>

      <Breadcrumbs
        breadcrumbs={[
          { name: breadcrumbAdmin, path: '/admin' },
          { name: breadcrumbPortfolios, path: '/admin/portfolios' },
          { name: portfolioTitle, path: `/admin/portfolios/${portfolioUuid}` },
          { name: breadcrumbAddPortfolioPropertyMember },
        ]}
      />

      <Form
        handleCreate={handlePortfolioPropertyMemberCreate}
        processing={processing}
        properties={propertiesToChooseFrom}
        minStart={active?.start}
        timezone={APIConfig()?.DEFAULT_TIMEZONE}
      />
    </>
  );
}

export default AdminPortfolioPropertyMemberCreate;

AdminPortfolioPropertyMemberCreate.propTypes = {
  router: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  portfolio: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  viewer: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
};

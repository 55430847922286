import PropTypes from 'prop-types';
import React from 'react';
import { NavLink } from 'reactstrap';

/**
 * Returns a custom link component with configurable link and target
 * @param {object} props
 * @param {React.ReactElement} props.children - child component to be shown
 * @param {string} props.link - hyperlink/external url
 * @param {string| ''} props.target - determines whether to load the url in same tab or a new one
 * @returns {React.ReactComponentElement} - customLink component
 */
function ExternalNavLink(props) {
  const { children, link, target } = props || {};
  if (!link || !children) {
    return null;
  }
  return (
    <NavLink target={target} href={link}>
      {children}
    </NavLink>
  );
}
ExternalNavLink.propTypes = {
  props: PropTypes.shape({
    // eslint-disable-next-line react/forbid-prop-types
    children: PropTypes.instanceOf(Element),
    link: PropTypes.string,
    target: PropTypes.string,
  }).isRequired,
};

export default ExternalNavLink;

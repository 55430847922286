import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useIntl } from 'react-intl';
import { graphql, useFragment } from 'react-relay';

import Loading from 'src/components/Loading';
import Breadcrumbs from 'src/enosikit/components/Breadcrumbs';
import usePortfolioUpdateMutation from 'src/mutations/PortfolioUpdateMutation';
import FlashesStore from 'src/stores/FlashesStore';

import Form from './Form';

const AdminPortfolioEditFragment = graphql`
  fragment AdminPortfolioEdit_portfolio on Portfolio {
    uuid
    title
  }
`;
/**
 * Description
 * @param {any} props
 * @returns {React.ReactComponentElement} - AdminPortfolioEdit component
 */
function AdminPortfolioEdit(props) {
  if (!props || Object.keys(props).length === 0) {
    return <Loading />;
  }
  const { router } = props;
  let { portfolio } = props;
  portfolio = useFragment(AdminPortfolioEditFragment, portfolio);
  const { title: portfolioTitle, uuid: portfolioUuid } = portfolio || {};

  const [processing, setProcessing] = useState(false);

  const intl = useIntl();
  const pageTitle = intl.formatMessage({ id: 'admin.admin_portfolio.admin_portfolio_edit.page.title', defaultMessage: 'Enosi Admin - Portfolio Edit' });
  const breadcrumbAdmin = intl.formatMessage({ id: 'admin.admin_portfolio.admin_portfolio_edit.breadcrumbs.admin.label', defaultMessage: 'Admin' });
  const breadcrumbPortfolios = intl.formatMessage({ id: 'admin.admin_portfolio.admin_portfolio_edit.breadcrumbs.portfolios.label', defaultMessage: 'Portfolios' });
  const breadcrumbEditPortfolio = intl.formatMessage({ id: 'admin.admin_portfolio.admin_portfolio_edit.breadcrumbs.update_portfolio.label', defaultMessage: 'Edit Portfolio' });
  const mutationName = 'editPortfolio';
  // start: form submit functionalities
  const handleEditPortfolioSuccess = (response, errors) => {
    setProcessing(false);

    if (errors && errors.length > 0) {
      errors.forEach((error) => FlashesStore.flash(FlashesStore.ERROR, {
        message: FlashesStore.ERROR,
        source: {
          errors: [error],
        },
      }, mutationName));
      return;
    }

    const { uuid } = response.updatePortfolio;

    FlashesStore.flash(
      FlashesStore.SUCCESS,
      <span>
        {intl.formatMessage({ id: 'admin.admin_portfolio.admin_portfolio_edit.response_message.success', defaultMessage: 'Successfully updated portfolio {uuid}.' }, { uuid })}
      </span>,
      mutationName,
    );

    router.push(`/admin/portfolios/${uuid}`);
  };

  const handleEditPortfolioFailure = (error) => {
    setProcessing(false);

    FlashesStore.flash(FlashesStore.ERROR, error, mutationName);
  };

  const editPortfolio = usePortfolioUpdateMutation();

  const handleEditPortfolio = (title) => {
    setProcessing(true);

    editPortfolio(
      portfolioUuid,
      { title },
      handleEditPortfolioSuccess,
      handleEditPortfolioFailure,
    );
  };

  // end: form submit functionalities

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{pageTitle}</title>
      </Helmet>

      <Breadcrumbs
        breadcrumbs={[
          { name: breadcrumbAdmin, path: '/admin' },
          { name: breadcrumbPortfolios, path: '/admin/portfolios' },
          { name: portfolioTitle, path: `/admin/portfolios/${portfolioUuid}` },
          { name: breadcrumbEditPortfolio },
        ]}
      />
      <Form
        handleEditPortfolio={handleEditPortfolio}
        processing={processing}
        portfolioTitle={portfolioTitle}
      />
    </>
  );
}
export default AdminPortfolioEdit;

AdminPortfolioEdit.propTypes = {
  router: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  portfolio: PropTypes.object.isRequired,
};

import { formatDefaultLocale } from 'd3-format';
import { scaleZoned } from 'd3-luxon';
import { timeFormatDefaultLocale } from 'd3-time-format';
import { DateTime } from 'luxon';
import PropTypes from 'prop-types';
import React from 'react';
import { useIntl } from 'react-intl';
import styled from 'styled-components';
import {
  VictoryAxis, VictoryBar, VictoryChart, VictoryLabel,
  VictoryStack, VictoryTooltip, VictoryVoronoiContainer,
} from 'victory';

import ChartTooltip from 'src/enosikit/components/Chart/components/ChartTooltip';
import {
  DATA_AGGREGATE_BY_METER, DATA_AGGREGATE_BY_PORTFOLIO, DATA_AGGREGATE_BY_PROPERTY,
  DATA_GROUP_BY_COUNTERPARTY, DATA_GROUP_BY_TRADE_TYPE,
} from 'src/util/constants';
import { getChartLocale } from 'src/util/i18n/constants';

import {
  BAR_RATIO, BUY, CHART_HEIGHT, ChartAxisStyle, SELL, TICK_COUNT,
  chartPadding,
} from './chartConstants';
import { chartDomain, handleTickFormat } from '../helpers/chart';
import {
  barColor, chartStateOpacity, formFinalChartData,
} from '../helpers/common';

const ChartWrapper = styled.div`
  svg {
    overflow: visible;
    position: relative;
    z-index: 999;
  }
`;

/**
 * Build and returns victory bar component for the trade and meter chart
 * @param {object} domain
 * @param {object} chartData
 * @param {string} chartOpacity
 * @param {string} key - residual, community ...
 * @param {Function} tooltipUpdate
 * @param {Function} handleChartClick
 * @returns {React.ReactElement} - bar charts.
 */
const chartBars = (domain, chartData, chartOpacity, key, tooltipUpdate, handleChartClick) => (
  <VictoryBar
    barRatio={BAR_RATIO}
    data={chartData}
    domain={domain}
    style={{
      data: {
        fill: ({ datum }) => (barColor(datum.y, key)), opacity: chartOpacity,
      },
    }}
    events={[
      {
        target: 'data',
        eventHandlers: {
          onMouseOver: (devent, datum) => {
            tooltipUpdate(datum.datum.fullDateObj);
          },
          onClick: () => {
            handleChartClick();
          },
        },
      },
    ]}
  />
);

/**
 * Build and returns trade and bar chart
 * @param {object} domain
 * @param {object} data - trade or meter data
 * @param {Function} compareX
 * @param {Function} tooltipUpdate
 * @param {Function} handleChartClick
 * @param {Array} hoverKeys
 * @param {Array} selectedKeys
 * @returns {React.ReactElement} - bar chart stacks.
 */
const chartStacks = (
  domain,
  data,
  compareX,
  tooltipUpdate,
  handleChartClick,
  hoverKeys = [],
  selectedKeys = [],
) => (
  <VictoryStack>
    {[SELL, BUY].map((direction) => {
      const rawChartData = direction === SELL
        ? Object.keys(data[direction]).reverse() : Object.keys(data[direction]);

      const series = rawChartData.map((key) => {
        const directionData = data[direction][key];
        if (directionData && Object.keys(directionData).length > 0) {
          const finalChartData = formFinalChartData(
            Object.values(directionData).sort(compareX),
            direction,
          );
          const chartOpacity = chartStateOpacity(key, hoverKeys, selectedKeys);
          return (
            chartBars(domain, finalChartData, chartOpacity, key, tooltipUpdate, handleChartClick)
          );
        }
        return null;
      });
      return direction === BUY ? series.reverse() : series;
    })}
  </VictoryStack>
);

/**
 * Description
 * @param {any} props
 * @returns {React.ReactComponentElement} - StackedBarChart component
 */
export default function StackedBarChart(props) {
  const {
    chartView, stackBarProps, stepSize, timespan,
    timezone, toolTipProps, width, yAxisFormat,
  } = props;

  const {
    data,
    hoverKeys,
    selectedKeys,
    compareX,
    tooltipUpdate,
    handleChartClick,
    chartType,
  } = stackBarProps;

  const {
    multipleMeter, unitLabel, tooltipDateFormat, tooltipData, tooltipTimestamp,
    tooltipFormat, tooltipLabelFunc, isCarbon,
  } = toolTipProps;

  const intl = useIntl();
  let formatter = yAxisFormat;
  const chartLocale = getChartLocale(intl.formatMessage);
  if (chartLocale && typeof (chartLocale) === 'object') {
    timeFormatDefaultLocale(chartLocale);
    const timeObj = formatDefaultLocale(chartLocale);
    formatter = timeObj.format(yAxisFormat);
  }
  const domain = chartDomain(data, stepSize, timespan);

  const scale = scaleZoned(timezone).domain(domain).range([0, width]);

  return (
    <ChartWrapper>
      <VictoryChart
        scale={{ x: scale, y: 'linear' }}
        domain={domain}
        height={CHART_HEIGHT}
        width={width}
        padding={chartPadding}
        containerComponent={(
          <VictoryVoronoiContainer
            labels={() => ' '}
            labelComponent={(
              <VictoryTooltip
                flyoutComponent={(
                  <ChartTooltip
                    chartType={chartType}
                    chartView={chartView}
                    chartWidth={width}
                    isCarbon={isCarbon}
                    multipleMeter={multipleMeter}
                    tooltipDateFormat={tooltipDateFormat}
                    tooltipData={tooltipData}
                    tooltipFormat={tooltipFormat}
                    tooltipLabelFunc={tooltipLabelFunc}
                    tooltipTimestamp={tooltipTimestamp}
                    tooltipUpdate={tooltipUpdate}
                    unitLabel={unitLabel}
                    yAxisFormat={yAxisFormat}
                  />
                )}
              />
            )}
          />
        )}
      >
        {
          chartStacks(
            domain,
            data,
            compareX,
            tooltipUpdate,
            handleChartClick,
            hoverKeys,
            selectedKeys,
          )
        }

        <VictoryAxis
          tickCount={TICK_COUNT}
          offsetY={20}
          style={ChartAxisStyle}
          fixLabelOverlap
          tickFormat={(tick) => handleTickFormat(timezone)(tick)}
        />
        <VictoryAxis
          label={unitLabel}
          axisLabelComponent={<VictoryLabel dx={0} dy={-15} />}
          style={ChartAxisStyle}
          crossAxis={false}
          orientation="left"
          dependentAxis
          fixLabelOverlap
          tickFormat={(t) => formatter(t)}
        />
      </VictoryChart>

    </ChartWrapper>
  );
}

StackedBarChart.propTypes = {
  chartView: PropTypes.shape({
    groupBy: PropTypes.oneOf([
      DATA_GROUP_BY_COUNTERPARTY,
      DATA_GROUP_BY_TRADE_TYPE,
    ]).isRequired,
    aggregateBy: PropTypes.oneOf([
      DATA_AGGREGATE_BY_METER,
      DATA_AGGREGATE_BY_PORTFOLIO,
      DATA_AGGREGATE_BY_PROPERTY,
    ]).isRequired,
  }).isRequired,
  stackBarProps: PropTypes.shape({
    data: PropTypes.oneOfType([PropTypes.object]),
    hoverKeys: PropTypes.oneOfType([PropTypes.array]),
    selectedKeys: PropTypes.oneOfType([PropTypes.array]),
    compareX: PropTypes.func,
    tooltipUpdate: PropTypes.func,
    handleChartClick: PropTypes.func,
    chartType: PropTypes.string,
  }),
  stepSize: PropTypes.oneOf(['P1D', 'PT30M']),
  timespan: PropTypes.shape({
    start: PropTypes.object, // eslint-disable-line react/forbid-prop-types
    finish: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  }).isRequired,
  timezone: PropTypes.string.isRequired,
  toolTipProps: PropTypes.shape({
    unitLabel: PropTypes.string,
    multipleMeter: PropTypes.bool,
    tooltipDateFormat: PropTypes.oneOfType([PropTypes.object]),
    tooltipData: PropTypes.oneOfType([PropTypes.object]),
    tooltipLabelFunc: PropTypes.func,
    tooltipTimestamp: PropTypes.instanceOf(DateTime),
    tooltipFormat: PropTypes.string,
    isCarbon: PropTypes.bool.isRequired,
  }),
  width: PropTypes.number.isRequired,
  yAxisFormat: PropTypes.string.isRequired,
};
StackedBarChart.defaultProps = {
  stackBarProps: null,
  toolTipProps: null,
  stepSize: 'P1D',
};

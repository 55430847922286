import { DateTime } from 'luxon';

import { BUY, SELL, Y_AXIS_DOMAIN_MULTIPLIER } from '../components/chartConstants';

/**
 * Because this is a stacked set of values we need to aggregate by date then
 * determine the maximum / minimum.
 * @param {object} rawChartData
 * @returns {object} domain updated based on the raw chart data.
 */
export const processDomainByDataset = (rawChartData) => {
  const updatedDomain = { x: null, y: null };
  const yValueAggregates = {};

  [SELL, BUY].forEach((dir) => {
    const datasets = rawChartData[dir];
    const yMultiplier = dir === SELL ? -1 : 1;

    yValueAggregates[dir] ||= {};

    Object.keys(datasets).forEach((id) => {
      const dataset = datasets[id];
      Object.keys(dataset).forEach((timestamp) => {
        const datum = dataset[timestamp] || {};
        if (datum) {
          updatedDomain.x ||= [datum.x, datum.x];

          if (updatedDomain.x[0] > datum.x) {
            updatedDomain.x[0] = datum.x;
          } else if (updatedDomain.x[1] < datum.x) {
            updatedDomain.x[1] = datum.x;
          }

          const yValue = yMultiplier * datum.y;

          yValueAggregates[dir][timestamp] ||= 0;
          yValueAggregates[dir][timestamp] += yValue;
        }
      });
    });
  });

  const yMin = Math.min(...Object.values(yValueAggregates[SELL]), 0);
  const yMax = Math.max(...Object.values(yValueAggregates[BUY]), 0);

  updatedDomain.y = [yMin, yMax];

  return updatedDomain;
};

/**
 * Processes the domain based on the timespan.
 * @param {object} rawChartData
 * @param {object} timespan
 * @param {string} stepSize - P1D or PT30M
 * @returns {object} domain object.
 */
export const processDomainByTimespan = (rawChartData, timespan, stepSize) => {
  if (!rawChartData || !timespan || !stepSize) {
    return { x: null, y: null };
  }
  const updatedDomain = { x: [timespan.start, timespan.finish], y: null };
  const yValueAggregates = {};

  [SELL, BUY].forEach((dir) => {
    const datasets = rawChartData[dir];
    const yMultiplier = dir === SELL ? -1 : 1;

    yValueAggregates[dir] ||= {};

    Object.keys(datasets).forEach((id) => {
      const dataset = datasets[id];
      Object.keys(dataset).forEach((timestamp) => {
        const datum = dataset[timestamp] || {};
        if (datum) {
          const yValue = yMultiplier * datum.y;

          yValueAggregates[dir][timestamp] ||= 0;
          yValueAggregates[dir][timestamp] += yValue;
        }
      });
    });
  });

  switch (stepSize) {
    case 'P1D':
      updatedDomain.x[1] = updatedDomain.x[1].minus({ day: 1 });
      break;
    case 'PT30M':
      updatedDomain.x[0] = updatedDomain.x[0].plus({ minutes: 30 });
      break;
    default:
      throw new Error(`Error: '${stepSize}' invalid`);
  }

  const yMin = Math.min(...Object.values(yValueAggregates[SELL]), 0);
  const yMax = Math.max(...Object.values(yValueAggregates[BUY]), 0);

  updatedDomain.y = [yMin, yMax];

  return updatedDomain;
};

/**
 * @param {object} rawChartData
 * @param {string} stepSize
 * @param {object} timespan
 * @returns {object} domain object.
 */
export const chartDomain = (rawChartData, stepSize, timespan) => {
  let xAxisDomainPadding = 0;
  switch (stepSize) {
    case 'P1D':
      xAxisDomainPadding = 24 * 60 * 60 * 1000; // 24 hrs in ms
      break;
    case 'PT30M':
      xAxisDomainPadding = 30 * 60 * 1000; // 30 mins in ms
      break;
    default:
      throw new Error(`Error: '${stepSize}' invalid`);
  }
  // eslint-disable-next-line no-constant-condition
  // placeholder for user preference.
  const domain = true
    ? processDomainByTimespan(rawChartData, timespan, stepSize)
    : processDomainByDataset(rawChartData);

  // Set the defaults if needed.
  if (domain.x === null || domain.y === null) {
    return { x: [0, 1], y: [0, 1] };
  }

  if (domain.y[0] > 0) { domain.y[0] = 0; }
  if (domain.y[1] < 0) { domain.y[1] = 0; }

  // Pad it out based on the bar chart step size.
  domain.x[0] -= xAxisDomainPadding / 2;
  domain.x[1] += xAxisDomainPadding / 2;
  // Pad out the y-values where they're above/below.
  if (domain.y[0] < 0) { domain.y[0] *= Y_AXIS_DOMAIN_MULTIPLIER; }
  if (domain.y[1] > 0) { domain.y[1] *= Y_AXIS_DOMAIN_MULTIPLIER; }

  return domain;
};

const formatMillisecond = 'SSS';
const formatSecond = ':ss';
const formatMinute = 'HH:mm';
const formatHour = 'h a';
const formatDay = 'ccc dd';
const formatWeek = 'LLL dd';
const formatMonth = 'LLLL';
const formatYear = 'yyyy';

/**
 * Formats the ticks that get rendered in the axis of the bar chart.
 * @param {string} zone - The time zone to use for formatting the ticks.
 * @returns {Function} - A function that formats the ticks in the bar chart axis.
 *   The returned function takes a tick as input and returns a formatted string.
 */
// eslint-disable-next-line arrow-body-style
export const handleTickFormat = (zone) => {
  /**
   * Formats a single tick value.
   * @param {string} tick - The tick to be formatted, provided as a JavaScript Date string.
   * @returns {string} - The formatted tick value.
   */
  return (tick) => {
    const timestamp = DateTime.fromMillis(tick.getTime()).setZone(zone);

    if (timestamp.startOf('second') < timestamp) {
      return timestamp.toFormat(formatMillisecond);
    }

    if (timestamp.startOf('minute') < timestamp) {
      return timestamp.toFormat(formatSecond);
    }

    if (timestamp.startOf('hour') < timestamp) {
      return timestamp.toFormat(formatMinute);
    }

    if (timestamp.startOf('day') < timestamp) {
      return timestamp.toFormat(formatHour);
    }

    if (timestamp.startOf('month') < timestamp) {
      if (timestamp.startOf('week') < timestamp) {
        return timestamp.toFormat(formatDay);
      }
      return timestamp.toFormat(formatWeek);
    }

    if (timestamp.startOf('year') < timestamp) {
      return timestamp.toFormat(formatMonth);
    }

    return timestamp.toFormat(formatYear);
  };
};

import { Auth } from 'aws-amplify';
import PropTypes from 'prop-types';
import React from 'react';
import { Helmet } from 'react-helmet-async';
import { injectIntl } from 'react-intl';
import {
  Button, Form, FormGroup, Label,
} from 'reactstrap';

import FlashesStore from 'src/stores/FlashesStore';
import { tracingState } from 'src/tracing';
import { shutdownIntercom } from 'src/util/intercom';

import { AuthLogoutContainer } from '../AuthContainer';

/**
 * Description
 * @param {object} root
 * @param {Function} root.onCompleted
 * @param {Function} root.onError
 * @returns {boolean} - returns whether signout is succeeded or not
 */
async function signOut({ onCompleted, onError }) {
  try {
    await Auth.signOut({ global: true });
    onCompleted();
    return true;
  } catch (error) {
    onError(error);
    return false;
  }
}

class AuthLogout extends React.Component {
  handleConfirmLogout = async (e) => {
    e.preventDefault();
    const { intl, router } = this.props;
    const mutationName = 'authLogout';

    const response = await signOut({
      onCompleted: () => {
        FlashesStore.flash(FlashesStore.SUCCESS, intl.formatMessage({ id: 'auth.auth_logout.form.response_message.success', defaultMessage: 'Logged out successfully' }), mutationName);
      },
      onError: (error) => {
        FlashesStore.flash(FlashesStore.ERROR, error.message, mutationName);
        console.log({ message: 'error signing out', error });
      },
    });
    if (response) {
      shutdownIntercom();
      router.push('/login');
      tracingState.clearUserID();
      tracingState.clearPropertyID();
    }
  };

  render() {
    const { intl, router } = this.props;
    const pageTitle = intl.formatMessage({ id: 'auth.auth_logout.page.title', defaultMessage: 'Logout of Enosi' });
    const formTitle = intl.formatMessage({ id: 'auth.auth_logout.form.title', defaultMessage: 'Confirm logout' });
    const confirmMsg = intl.formatMessage({ id: 'auth.auth_logout.form.confirmation.message', defaultMessage: 'Are you sure you want to leave?' });
    const goBackLabel = intl.formatMessage({ id: 'auth.auth_logout.form.go_back.label', defaultMessage: '« Go back' });
    const logOutLabel = intl.formatMessage({ id: 'auth.auth_logout.form.log_out.label', defaultMessage: 'Log out' });

    return (
      <>
        <Helmet>
          <meta charSet="utf-8" />
          <title>{pageTitle}</title>
        </Helmet>
        <AuthLogoutContainer title={formTitle}>
          <Form onSubmit={this.handleConfirmLogout}>
            <FormGroup>
              <Label htmlFor="formConfirm">{confirmMsg}</Label>
            </FormGroup>
            <Button color="" onClick={() => (router.go(-1))}>
              {goBackLabel}
            </Button>
            {' '}
            <Button type="submit" color="primary">
              {logOutLabel}
            </Button>
          </Form>
        </AuthLogoutContainer>
      </>
    );
  }
}

AuthLogout.propTypes = {
  intl: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  router: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
};

export default injectIntl(AuthLogout);

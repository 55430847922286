import { Link } from 'found';
import PropTypes from 'prop-types';
import React from 'react';
import { Helmet } from 'react-helmet-async';

import Loading from 'src/components/Loading';
import Breadcrumbs from 'src/enosikit/components/Breadcrumbs';
import InviteCustomerUserMutation from 'src/mutations/InviteCustomerUserMutation';
import FlashesStore from 'src/stores/FlashesStore';

import AdminUserInviteCustomerForm from './AdminUserInviteCustomerForm';
import AdminUserControls from '../AdminUserControls';

class AdminUserInviteCustomer extends React.Component {
  static mutationName = 'adminUserInviteCustomer';

  constructor(props) {
    super(props);

    this.state = {
      processing: false,
    };
  }

  handleAdminUserInviteCustomer = (input) => {
    this.setState({ processing: true });
    FlashesStore.reset();

    InviteCustomerUserMutation(
      input,
      this.handleAdminUserInviteCustomerSuccess,
      this.handleAdminUserInviteCustomerFailure,
    );
  };

  handleAdminUserInviteCustomerSuccess = (response, errors) => {
    this.setState({ processing: false });

    if (errors && errors.length > 0) {
      errors.forEach((error) => FlashesStore.flash(FlashesStore.ERROR, {
        message: FlashesStore.ERROR,
        source: {
          errors: [error],
        },
      }, AdminUserInviteCustomer.mutationName));
      return;
    }

    const { uuid, email } = response.inviteCustomerUser;

    FlashesStore.flash(
      FlashesStore.SUCCESS,
      <span>
        An email invitation has been sent to
        <Link to={`/admin/users/${uuid}`}>
          {` ${email} `}
        </Link>
        to finish their account registration.
      </span>,
      AdminUserInviteCustomer.mutationName,
    );

    const { router } = this.props;
    router.push('/admin/users/');
  };

  handleAdminUserInviteCustomerFailure = (error) => {
    this.setState({ processing: false });

    FlashesStore.flash(FlashesStore.ERROR, error, AdminUserInviteCustomer.mutationName);
  };

  render() {
    if (this.error) {
      return <div>Error!</div>;
    }
    if (!this.props) {
      return <Loading />;
    }

    const { processing } = this.state;

    return (
      <>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Enosi - Admin - Users - Invite Customer</title>
        </Helmet>

        <Breadcrumbs
          breadcrumbs={[
            { name: 'Admin', path: '/admin' },
            { name: 'Users', path: '/admin/users' },
            { name: 'Invite new customer' },
          ]}
        />
        <div className="clearfix">
          <div className="float-end mb-3">
            <AdminUserControls />
          </div>
        </div>

        <AdminUserInviteCustomerForm
          handleInvite={this.handleAdminUserInviteCustomer}
          processing={processing}
        />
      </>
    );
  }
}

AdminUserInviteCustomer.propTypes = {
  relay: PropTypes.shape({
    refetch: PropTypes.func,
  }).isRequired,
  router: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
};

AdminUserInviteCustomer.defaultProps = {};

export default AdminUserInviteCustomer;

import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

const Overlay = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ffffff85; 
  cursor: not-allowed;
  height: 100%; 
  left: 0; 
  position: fixed; 
  top: 0; 
  width: 100%; 
  z-index: 10000;

`;

/**
 * Full screen overlay component that displays a loading spinner and a label.
 * @param {object} props
 * @param {string} props.label
 * @returns {React.ReactComponentElement} - Loading overlay component
 */
function LoadingOverlay({ label }) {
  return (
    <Overlay>
      <div className="d-flex flex-column align-items-center">
        <div className="spinner-border text-primary" role="status" />
        <span className="fs-4">
          {label}
        </span>
      </div>
    </Overlay>
  );
}

export default LoadingOverlay;

LoadingOverlay.propTypes = {
  label: PropTypes.string.isRequired,
};

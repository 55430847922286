import { DateTime } from 'luxon';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Typeahead } from 'react-bootstrap-typeahead';
import { useIntl } from 'react-intl';
import {
  Button, Card, CardBody, CardFooter, CardHeader,
  FormFeedback, FormGroup, FormText, Input, Label, Form as ReactstrapForm,
} from 'reactstrap';

import Address from 'src/components/Address';
import { activeStartStringToDateTime, validateExternalIdentifierInput } from 'src/components/Form/Input/utils';
import FlashesStore from 'src/stores/FlashesStore';

/**
 * @param {object} props
 * @param {Function} props.handleCreate function handling the mutation.
 * @param {boolean} props.processing whether the mutation is being processed.
 * @param {Array<object>} props.properties list of properties to choose from.
 * @param {number} props.minStart The minimum active start which the user can select
 *                                as a unix timestamp (coming from the graphql).
 * @param {string} props.timezone The partner's timezone
 * @returns {React.ReactComponentElement} Form component.
 */
function Form({
  handleCreate,
  processing,
  properties,
  minStart,
  timezone,
}) {
  const defaultActiveStart = minStart
    ? DateTime.fromSeconds(minStart).toUTC().toISO() : null;
  const [selectedActiveStart, setSelectedActiveStart] = useState(defaultActiveStart);
  const [contractCommencement, setContractCommencement] = useState(defaultActiveStart);
  const [activeStartInvalid, setActiveStartInvalid] = useState(false);
  const [property, setProperty] = useState(null);
  const [propertyValid, setPropertyValid] = useState(null);
  const [externalIdentifier, setExternalIdentifier] = useState('');
  const [externalIdentifierInvalid, setExternalIdentifierInvalid] = useState(false);
  const [externalIdentifierFeedback, setExternalIdentifierFeedback] = useState(null);

  const intl = useIntl();
  const maximumLength = 1024;

  const errorMsgs = {
    MAXIMUM_LIMIT_EXCEEDED: intl.formatMessage({
      id: 'admin.admin_portfolio.admin_portfolio_property_member_create.form.external_identifier.invalid_external_identifier.max_length_exceeded.feedback',
      defaultMessage: 'External identifier cannot have more than {maximumLength} characters',
    }, { maximumLength }),
    HAS_LEADING_SPACE: intl.formatMessage({
      id: 'admin.admin_portfolio.admin_portfolio_property_member_create.form.external_identifier.invalid_external_identifier_has_leading_space.feedback',
      defaultMessage: 'External identifier cannot have a leading space',
    }),
    HAS_TRAILING_SPACE: intl.formatMessage({
      id: 'admin.admin_portfolio.admin_portfolio_property_member_create.form.external_identifier.invalid_external_identifier.has_trailing_space.feedback',
      defaultMessage: 'External identifier cannot have a trailing space',
    }),
  };

  const isValid = propertyValid !== null && propertyValid;

  const getMinActiveStart = (propertyActiveStart) => {
    const propertyStart = propertyActiveStart
      ? DateTime.fromSeconds(propertyActiveStart) : null;
    const portfolioStart = defaultActiveStart ? DateTime.fromISO(defaultActiveStart) : null;
    return propertyStart && propertyStart > portfolioStart
      ? propertyStart : portfolioStart;
  };

  // start: validation functions
  const validateExternalIdentifier = (extIdentifierString) => {
    const feedbacks = validateExternalIdentifierInput(
      extIdentifierString,
      maximumLength,
    );
    const feedbackMsg = feedbacks?.map((item) => errorMsgs[item]);
    setExternalIdentifierInvalid(feedbacks?.length > 0);
    setExternalIdentifierFeedback(feedbackMsg);
  };
  const validateProperty = () => {
    if (!property) {
      setPropertyValid(false);
    }
  };

  const validateActiveStart = () => {
    if (!selectedActiveStart) {
      setSelectedActiveStart(defaultActiveStart);
      setActiveStartInvalid(false);
      return false;
    }
    const finalActiveStart = activeStartStringToDateTime(selectedActiveStart, timezone);
    const finalContractCommencement = DateTime.fromISO(contractCommencement);
    if ((!finalActiveStart?.isValid)
      || finalActiveStart < finalContractCommencement
      || finalActiveStart > DateTime.now()) {
      setActiveStartInvalid(true);
      if (finalActiveStart?.isValid && selectedActiveStart !== finalActiveStart.toUTC().toISO()) {
        setSelectedActiveStart(finalActiveStart.toUTC().toISO());
        return false;
      }
    }
    return true;
  };

  const validateForm = () => {
    validateActiveStart();
    validateProperty();
    validateExternalIdentifier(externalIdentifier);
  };

  // end: validation functions

  const handleSubmit = (event) => {
    event.preventDefault();

    if (!isValid) {
      const msgInvalidData = intl.formatMessage({ id: 'admin.admin_portfolio.admin_portfolio_property_member_create.form.state.invalid_data', defaultMessage: 'Form data not valid. Please see below.' });
      FlashesStore.flash(FlashesStore.ERROR, msgInvalidData, 'portfolioAddProperty');
      return;
    }

    validateForm();

    if (processing || !propertyValid || externalIdentifierInvalid || activeStartInvalid) {
      return;
    }

    const input = {
      activeStart: selectedActiveStart
        ? DateTime.fromISO(selectedActiveStart).toSeconds() : null,
      externalIdentifier: externalIdentifier || null,
      propertyId: property.uuid,
    };

    handleCreate(input);
  };

  const handlePropertyChange = (selected) => {
    if (selected.length > 0) {
      const [selectedProperty] = selected;
      setProperty(selectedProperty);
      const minActiveStartDateTime = getMinActiveStart(selectedProperty?.activeStart);
      if (minActiveStartDateTime) {
        setContractCommencement(minActiveStartDateTime.toUTC().toISO());
        if (selectedActiveStart === defaultActiveStart) {
          setSelectedActiveStart(minActiveStartDateTime.toUTC().toISO());
        }
      }
      setPropertyValid(true);
    } else {
      setPropertyValid(false);
    }
  };

  const handleExternalIdentifierChange = (event) => {
    const { value: inputExternalIdentifier } = event.target;
    validateExternalIdentifier(inputExternalIdentifier);
    setExternalIdentifier(inputExternalIdentifier);
  };

  const handleActiveStartBlur = () => {
    const isValidActiveStart = validateActiveStart();
    if (isValidActiveStart) {
      const finalActiveStart = activeStartStringToDateTime(selectedActiveStart, timezone);
      setSelectedActiveStart(finalActiveStart.toUTC().toISO());
      setActiveStartInvalid(false);
    }
  };

  const handleActiveStartChange = (event) => {
    const { value: inputDateTime } = event.target;
    setSelectedActiveStart(inputDateTime);
    const formattedDateTime = DateTime.fromISO(inputDateTime);
    if (formattedDateTime.isValid) {
      if (formattedDateTime > DateTime.fromISO(contractCommencement)
        && formattedDateTime < DateTime.now()) {
        setActiveStartInvalid(false);
      } else {
        setActiveStartInvalid(true);
      }
    } else {
      setActiveStartInvalid(true);
    }
  };

  const propertyOptions = properties?.map((el) => {
    const { node } = el || {};
    const {
      active, uuid, title, address,
    } = node || {};
    if (!address) {
      return { activeStart: active?.start, uuid, label: title };
    }

    const addr = new Address(address);
    const label = `${title} (${addr?.string()})`;

    return { activeStart: active?.start, uuid, label };
  });

  const propertyFeedback = () => (
    <>
      {properties?.length === 0 && (
        <FormText>
          {intl.formatMessage({ id: 'admin.admin_portfolio.admin_portfolio_property_member_create.form.no_properties_to_add.hint', defaultMessage: 'All the properties available are already in the portfolio' })}
        </FormText>
      )}
      {propertyValid != null && !propertyValid && (
        <FormText>
          {intl.formatMessage({ id: 'admin.admin_portfolio.admin_portfolio_property_member_create.form.empty_input.feedback.label', defaultMessage: 'Property cannot be empty' })}
        </FormText>
      )}
    </>
  );

  const submissionDisabled = processing || properties.length === 0
    || !propertyValid || externalIdentifierInvalid || activeStartInvalid;

  const invalidDateMessage = intl.formatMessage({ id: 'admin.admin_portfolio.admin_portfolio_property_member_create.form.active_start.feedback', defaultMessage: 'The start cannot be before the portfolio or property\'s start date: {contractCommencement}' }, { contractCommencement });

  const activeStartHint = intl.formatMessage({ id: 'admin.admin_portfolio.admin_portfolio_property_member_create.form.active_start.hint', defaultMessage: '<rfc3339Link>RFC3339 time format</rfc3339Link> <code>YYYY-MM-DDTHH:MM:SSZ</code> (for example, {minStart})' }, { minStart: contractCommencement, code: (str) => (<code>{str}</code>), rfc3339Link: (str) => (<a href="https://www.rfc-editor.org/rfc/rfc3339">{str}</a>) });

  return (
    <ReactstrapForm onSubmit={handleSubmit} disabled={processing} data-testid="form">
      <Card>
        <CardHeader tag="h2">
          {intl.formatMessage({ id: 'admin.admin_portfolio.admin_portfolio_property_member_create.form.heading.label', defaultMessage: 'Add new portfolio property member' })}
        </CardHeader>
        <CardBody>
          <FormGroup>
            <Label for="property">{intl.formatMessage({ id: 'admin.admin_portfolio.admin_portfolio_property_member_create.form.role.label', defaultMessage: 'Property' })}</Label>
            <Typeahead
              clearButton
              id="property"
              onChange={handlePropertyChange}
              disabled={processing}
              options={propertyOptions}
              isValid={propertyValid}
              isInvalid={propertyValid !== null && !propertyValid}
              style={{ marginBottom: '0.25rem' }}
            />
            {propertyFeedback()}
          </FormGroup>
          <FormGroup>
            <Label for="externalIdentifier">{intl.formatMessage({ id: 'admin.admin_portfolio.admin_portfolio_property_member_create.form.external_identifier.label', defaultMessage: 'External identifier (optional)' })}</Label>
            <Input type="text" name="external identifier" id="externalIdentifier" value={externalIdentifier} onChange={handleExternalIdentifierChange} disabled={processing} invalid={externalIdentifierInvalid} />
            <FormText>{intl.formatMessage({ id: 'admin.admin_portfolio.admin_portfolio_property_member_create.form.external_identifier.hint', defaultMessage: 'External identifier can have a maximum of {maximumLength} characters with no leading or trailing spaces' }, { maximumLength })}</FormText>
            {externalIdentifierFeedback?.length > 0
              && externalIdentifierFeedback.map((feedback) => (
                <FormFeedback key={feedback}>{feedback}</FormFeedback>
              ))}
          </FormGroup>
          <FormGroup>
            <Label for="activeStart">
              {intl.formatMessage({ id: 'admin.admin_portfolio.admin_portfolio_property_member_create.form.active_start.label', defaultMessage: 'Active start (optional)' })}
            </Label>
            <Input id="activeStart" onChange={handleActiveStartChange} value={selectedActiveStart} type="text" name="activeStart" disabled={processing} invalid={activeStartInvalid} onBlur={handleActiveStartBlur} />
            <FormText>{activeStartHint}</FormText>
            <FormFeedback>{invalidDateMessage}</FormFeedback>
          </FormGroup>
        </CardBody>
        <CardFooter>
          <Button disabled={submissionDisabled}>{intl.formatMessage({ id: 'admin.admin_portfolio.admin_portfolio_property_member_create.form.submit.label', defaultMessage: 'Add new portfolio property member' })}</Button>
        </CardFooter>
      </Card>
    </ReactstrapForm>
  );
}

export default Form;

Form.propTypes = {
  handleCreate: PropTypes.func.isRequired,
  minStart: PropTypes.number,
  processing: PropTypes.bool.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  properties: PropTypes.arrayOf(PropTypes.object).isRequired,
  timezone: PropTypes.string.isRequired,
};

Form.defaultProps = {
  minStart: null,
};

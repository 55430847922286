import { format } from 'd3-format';
import { Link } from 'found';
import PropTypes from 'prop-types';
import React from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { FormattedMessage, injectIntl } from 'react-intl';
import { createFragmentContainer, graphql } from 'react-relay';
import {
  Badge, Button, Card, CardBody, CardFooter, CardSubtitle, CardText, CardTitle, Col, Row,
} from 'reactstrap';
import styled from 'styled-components';

import Loading from 'src/components/Loading';
import { APIConfig } from 'src/config';
import Time from 'src/enosikit/components/Time/components/Time';
import { getTradeRuleMeterLabel } from 'src/helpers/tradeHelpers';
import PrioritizeNominatedTradesMutation from 'src/mutations/PrioritizeNominatedTradesMutation';
import FlashesStore from 'src/stores/FlashesStore';
import { PLATFORM_MODE_REBATE, TRADE_DIRECTION_BUY } from 'src/util/constants';
import convertEnergyPrice from 'src/util/conversions';
import username from 'src/util/decorators/username';
import { getStringAndNumericFormattedDate } from 'src/util/i18n/helpers';
import { tradeRulePriceRange } from 'src/util/tradeRule';

const DraggableCard = styled.div.attrs({
  className: 'card mb-4',
})`
  box-sizing: content-box;
  padding-right: 3rem;
`;

const DraggableCardHandle = styled.div`
  position: absolute;
  right: 0;
  vertical-align: middle;
  width: 3rem;
  background: #eee;
  height: 100%;
`;

const DraggableCardHandleIcon = styled.div`
  width: 100%;
  margin-top: 5rem;

  &:before {
    content: '.';
    font-size: 1rem;
    line-height: 1rem;
    text-shadow: 0 5px #666, 0 10px #666, 5px 0 #666, 5px 5px #666, 5px 10px #666, 10px 0 #666, 10px 5px #666, 10px 10px #666;
    color: #666;
    padding-left: 1rem;
    text-align: center;
  }
`;

class TradeRulePrioritizeNominatedList extends React.Component {
  static mutationName = 'prioritizeNominatedTrades';

  constructor(props) {
    super(props);

    const { meter } = props;

    const rules = {};
    if (meter && meter.nominatedRules && meter.nominatedRules.edges) {
      meter.nominatedRules.edges.forEach((edge) => {
        const { node } = edge;
        if (node.buyer.tradePoint.uuid === meter.tradePointId) {
          rules[node.uuid] = node;
        }
      });
    }

    const ruleIds = Object.values(rules)
      .sort((a, b) => a.priority - b.priority)
      .map((rule) => rule.uuid);

    this.state = {
      rules,
      ruleIds,
      processing: false,
    };
  }

  handleConfirm = () => {
    const { intl, meter } = this.props;
    const { ruleIds, processing } = this.state;

    const messageProcessing = intl.formatMessage({ id: 'trade_rule.trade_rule_prioritize_nominated.form.state.invalid_still_processing', defaultMessage: 'We are still processing your request...' });

    if (processing) {
      FlashesStore.flash(
        FlashesStore.INFO,
        messageProcessing,
        TradeRulePrioritizeNominatedList.mutationName,
      );
      return;
    }

    this.setState({ processing: true });
    FlashesStore.reset();

    PrioritizeNominatedTradesMutation(
      meter.tradePointId,
      ruleIds.map((uuid, index) => ({ uuid, priority: index + 1 })),
      this.handleConfirmSuccess,
      this.handleConfirmFailure,
    );
  };

  handleConfirmSuccess = (_response) => {
    const { intl, property, router } = this.props;
    const messageSuccess = intl.formatMessage({ id: 'trade_rule.trade_rule_prioritize_nominated.form.response_message.success', defaultMessage: 'Updated peer-to-peer trades priority order.' });

    this.setState({ processing: false });
    FlashesStore.flash(
      FlashesStore.SUCCESS,
      messageSuccess,
      TradeRulePrioritizeNominatedList.mutationName,
    );
    router.push(`/properties/${property.uuid}/trade-rules/active`);
  };

  handleConfirmFailure = (error) => {
    this.setState({ processing: false });

    FlashesStore.flash(
      FlashesStore.ERROR,
      error,
      TradeRulePrioritizeNominatedList.mutationName,
    );
  };

  onDragEnd = (result) => {
    const { destination, source, draggableId } = result;
    const { ruleIds } = this.state;

    if (!destination) {
      return;
    }

    const newRuleIds = [...ruleIds];
    newRuleIds.splice(source.index, 1);
    newRuleIds.splice(destination.index, 0, draggableId);

    this.setState({ ruleIds: newRuleIds });
  };

  render() {
    if (this.error) {
      return <div><FormattedMessage id="error.title" defaultMessage="Error!" /></div>;
    }
    if (!this.props) {
      return <Loading />;
    }

    const {
      intl, property, meter, router,
    } = this.props;
    const { rules, ruleIds, processing } = this.state;
    const tradeRules = ruleIds.map((ruleId) => rules[ruleId]);

    const pricePerUnitTitle = intl.formatMessage({ id: 'trade_rule.trade_rule_prioritize_nominated.trade_rule_prioritize_nominated_list.sell_price.energy_cost.abbr.title', defaultMessage: 'cents per kilowatt hour' });
    const pricePerUnitLabel = intl.formatMessage({ id: 'trade_rule.trade_rule_prioritize_nominated.trade_rule_prioritize_nominated_list.sell_price.energy_cost.abbr.label', defaultMessage: 'c/kWh' });

    const energyPricePerUnit = <abbr title={pricePerUnitTitle}>{pricePerUnitLabel}</abbr>;

    return (
      <Card className="mt-4 mb-4 trade-rules-priority-list">
        <CardBody>
          <h2>
            <FormattedMessage id="trade_rule.trade_rule_prioritize_nominated.trade_rule_prioritize_nominated_list.header.text" defaultMessage="Peer-to-Peer Trades for {meterTitle}" values={{ meterTitle: meter?.title }} />
          </h2>
          <Row>
            <Col xs="12" sm="12">
              <h3><FormattedMessage id="trade_rule.trade_rule_prioritize_nominated.trade_rule_prioritize_nominated_list.change_priority.text" defaultMessage="Change buying priority order" /></h3>
              <p data-testid="subHeading">
                <FormattedMessage id="trade_rule.trade_rule_prioritize_nominated.trade_rule_prioritize_nominated_list.reorder.text" defaultMessage="Reorder the priority of your {tradeRules} peer-to-peer trade rules to buy energy." values={{ tradeRules: <strong>{tradeRules.length}</strong> }} />
              </p>
              <DragDropContext onDragEnd={this.onDragEnd}>
                <Droppable droppableId="droppable">
                  {((droppableProvided) => (
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    <div ref={droppableProvided.innerRef} {...droppableProvided.droppableProps}>
                      {tradeRules.map((rule, index) => {
                        const {
                          acceptedAt, acceptedBy, buyer, uuid,
                          priority, proposedAt, proposedBy, seller,
                        } = rule;
                        const newPriority = index + 1;

                        const priceRange = tradeRulePriceRange(rule);
                        const furtherDetails = <Link to={`/properties/${property.uuid}/trade-rules/${uuid}`}><FormattedMessage id="trade_rule.trade_rule_prioritize_nominated.trade_rule_prioritize_nominated_list.further_details.text" defaultMessage="further details" /></Link>;

                        let priceLabel;
                        if (APIConfig().MODE === PLATFORM_MODE_REBATE) {
                          if (priceRange.maximum === priceRange.minimum) {
                            priceLabel = (
                              <FormattedMessage
                                id="trade_rule.trade_rule_prioritize_nominated.trade_rule_prioritize_nominated_list.mode_rebate.sell_price.flat"
                                defaultMessage="Discount of {price} {energyPricePerUnit} ({furtherDetails})"
                                values={{
                                  price: <strong>{format('.3f')(convertEnergyPrice(priceRange.minimum))}</strong>,
                                  energyPricePerUnit,
                                  furtherDetails,
                                }}
                              />
                            );
                          }
                          if (priceRange.maximum !== priceRange.minimum) {
                            priceLabel = (
                              <FormattedMessage
                                id="trade_rule.trade_rule_prioritize_nominated.trade_rule_prioritize_nominated_list.mode_rebate.sell_price.variable"
                                defaultMessage="Discount of between {minPrice} and {maxPrice} {energyPricePerUnit} ({furtherDetails})"
                                values={{
                                  minPrice: <strong>{format('.3f')(convertEnergyPrice(priceRange.minimum))}</strong>,
                                  maxPrice: <strong>{format('.3f')(convertEnergyPrice(priceRange.maximum))}</strong>,
                                  energyPricePerUnit,
                                  furtherDetails,
                                }}
                              />
                            );
                          }
                        } else {
                          if (priceRange.maximum === priceRange.minimum) {
                            priceLabel = (
                              <FormattedMessage
                                id="trade_rule.trade_rule_prioritize_nominated.trade_rule_prioritize_nominated_list.mode_trade.sell_price.flat"
                                defaultMessage="Sell price of {price} {energyPricePerUnit} ({furtherDetails})"
                                values={{
                                  price: <strong>{format('.3f')(convertEnergyPrice(priceRange.minimum))}</strong>,
                                  energyPricePerUnit,
                                  furtherDetails,
                                }}
                              />
                            );
                          }
                          if (priceRange.maximum !== priceRange.minimum) {
                            priceLabel = (
                              <FormattedMessage
                                id="trade_rule.trade_rule_prioritize_nominated.trade_rule_prioritize_nominated_list.mode_trade.sell_price.variable"
                                defaultMessage="Sell price of between {minPrice} and {maxPrice} {energyPricePerUnit} ({furtherDetails})"
                                values={{
                                  minPrice: <strong>{format('.3f')(convertEnergyPrice(priceRange.minimum))}</strong>,
                                  maxPrice: <strong>{format('.3f')(convertEnergyPrice(priceRange.maximum))}</strong>,
                                  energyPricePerUnit,
                                  furtherDetails,
                                }}
                              />
                            );
                          }
                        }

                        return (
                          <Draggable draggableId={uuid} index={index} key={`trade-rules-${uuid}`}>
                            {(draggableProvided) => (
                              <DraggableCard
                                ref={draggableProvided.innerRef}
                                // eslint-disable-next-line react/jsx-props-no-spreading
                                {...draggableProvided.draggableProps}
                              >
                                <CardBody>
                                  <CardTitle tag="h4">
                                    <FormattedMessage
                                      id="trade_rule.trade_rule_prioritize_nominated.trade_rule_prioritize_nominated_list.priority.label"
                                      defaultMessage="Priority {priority} {previousPriority}"
                                      values={{
                                        priority: newPriority,
                                        previousPriority: newPriority !== priority && (
                                          <s>{`(${priority})`}</s>
                                        ),
                                      }}
                                    />
                                  </CardTitle>
                                  <CardSubtitle tag="h5" className="mb-2">
                                    <FormattedMessage id="trade_rule.trade_rule_prioritize_nominated.trade_rule_prioritize_nominated_list.approved.label" defaultMessage="Approved" />
                                  </CardSubtitle>
                                  <CardText>
                                    <FormattedMessage
                                      id="trade_rule.trade_rule_prioritize_nominated.trade_rule_prioritize_nominated_list.trade_direction_buy.details"
                                      defaultMessage="Buying for {meter}"
                                      values={{
                                        meter: getTradeRuleMeterLabel(
                                          buyer,
                                          seller,
                                          TRADE_DIRECTION_BUY,
                                        ),
                                      }}
                                    />
                                    <br />
                                    <FormattedMessage
                                      id="trade_rule.trade_rule_prioritize_nominated.trade_rule_prioritize_nominated_list.proposal.details"
                                      defaultMessage="Proposed by {seller} on {timestamp}"
                                      values={{
                                        seller: <strong>{username(proposedBy)}</strong>,
                                        timestamp: <Time
                                          child={getStringAndNumericFormattedDate(intl, proposedAt)}
                                          unixTimestamp={proposedAt}
                                        />,
                                      }}
                                    />
                                    <br />
                                    {acceptedAt && (
                                      <FormattedMessage
                                        id="trade_rule.trade_rule_prioritize_nominated.trade_rule_prioritize_nominated_list.accepted.details"
                                        defaultMessage="Accepted by {buyer} on {timestamp}"
                                        values={{
                                          buyer: <strong>{username(acceptedBy)}</strong>,
                                          timestamp: <Time
                                            child={getStringAndNumericFormattedDate(
                                              intl,
                                              acceptedAt,
                                            )}
                                            unixTimestamp={acceptedAt}
                                          />,
                                        }}
                                      />
                                    )}
                                    <br />
                                    {priceLabel}
                                    <br />
                                    <Link
                                      to={`/properties/${property.uuid}/trade-rules/${uuid}`}
                                    >
                                      <Badge color="mid">{uuid}</Badge>
                                    </Link>
                                  </CardText>
                                </CardBody>

                                {/* eslint-disable-next-line react/jsx-props-no-spreading */}
                                <DraggableCardHandle {...draggableProvided.dragHandleProps} className="trade-rules-priority-handler">
                                  <DraggableCardHandleIcon />
                                </DraggableCardHandle>
                              </DraggableCard>
                            )}
                          </Draggable>
                        );
                      })}
                      {droppableProvided.placeholder}
                    </div>
                  ))}
                </Droppable>
              </DragDropContext>
            </Col>
          </Row>
        </CardBody>
        <CardFooter>
          <Button color="primary" className="me-2" onClick={this.handleConfirm} disabled={processing}>
            <FormattedMessage id="trade_rule_priority.confirm_order.label" defaultMessage="Confirm order" />
          </Button>
          <Button color="" onClick={() => (router.go(-1))} disabled={processing}>
            <FormattedMessage id="trade_rule_priority.cancel_changes.label" defaultMessage="Cancel changes" />
          </Button>
        </CardFooter>
      </Card>
    );
  }
}

TradeRulePrioritizeNominatedList.propTypes = {
  intl: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  meter: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  property: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  router: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
};

TradeRulePrioritizeNominatedList.defaultProps = {
  property: null,
  meter: null,
};

export default injectIntl(createFragmentContainer(
  TradeRulePrioritizeNominatedList,
  {
    property: graphql`
      fragment TradeRulePrioritizeNominatedList_property on Property {
        uuid
      }
    `,
    meter: graphql`
      fragment TradeRulePrioritizeNominatedList_meter on Meter {
        uuid
        identifier
        title
        tradePointId
        nominatedRules: rules(first: 500, type: TRADE_TYPE_NOMINATED, state: TRADE_RULE_STATE_ACCEPTED) {
          edges {
            node {
              uuid
              priority
              tradeType
              state
              buyer {
                userId
                communityId
                residualId
                tradePoint {
                  uuid
                  meter {
                    uuid
                    identifier
                    title
                    property {
                      uuid
                      title
                    }
                  }
                }
              }
              seller {
                userId
                communityId
                residualId
                tradePoint {
                  uuid
                  meter {
                    uuid
                    identifier
                    title
                    property {
                      uuid
                      title
                    }
                  }
                }
              }
              clauses {
                edges {
                  node {
                    price
                  }
                }
              }
              start
              finish
              proposedAt
              proposedBy {
                uuid
                email
                givenName
                familyName
              }
              acceptedAt
              acceptedBy {
                uuid
                email
                givenName
                familyName
              }
            }
          }
        }
      }
    `,
  },
));

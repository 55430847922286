import { commitMutation, graphql } from 'react-relay';

import environment from 'src/util/environment';

const mutation = graphql`
  mutation AcceptNominatedTradeMutation($uuid: ID!, $tradePointId: String!) {
    acceptNominatedTrade(uuid: $uuid, tradePointId: $tradePointId) {
      id
      uuid
    }
  }
`;

/**
 * Accept nominated trade mutation.
 * @param {object} tradeRuleIdSet id, UUID and tradePointId of the trade rule to be accepted.
 * @param {Function} onCompleted Function to call on success.
 * @param {Function} onError Function to call on error.
 * @param {object} formatMessage i18n object
 */
const AcceptNominatedTradeMutation = (
  tradeRuleIdSet,
  onCompleted,
  onError,
  formatMessage,
) => {
  const { id, tradePointId, uuid } = tradeRuleIdSet;

  commitMutation(
    environment,
    {
      mutation,
      variables: { uuid, tradePointId },
      onCompleted: (response) => { onCompleted(response, formatMessage); },
      onError,
      updater: (store) => {
        // Get the current trade rule record from the store.
        const ruleProxy = store.get(id);
        ruleProxy.setValue('TRADE_RULE_STATE_ACCEPTED', 'state');
      },
    },
  );
};

export default AcceptNominatedTradeMutation;

import PropTypes from 'prop-types';
import React from 'react';
import { injectIntl } from 'react-intl';
import {
  Button, Card, CardBody, CardFooter, CardHeader,
  Form, FormFeedback, FormGroup, FormText, Input, Label,
} from 'reactstrap';
import isEmail from 'validator/es/lib/isEmail';

import FlashesStore from 'src/stores/FlashesStore';

class AdminUserInviteCustomerForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      email: '',
      givenName: '',
      familyName: '',
      emailValid: null,
    };
  }

  isValid = () => {
    const { emailValid } = this.state;

    return emailValid !== null && emailValid;
  };

  handleSubmit = (event) => {
    event.preventDefault();

    const { handleInvite, processing, intl } = this.props;
    const infoMsg = intl.formatMessage({ id: 'admin.admin_user.admin_user_invite_customer.form.response_message.info', defaultMessage: 'We are still processing your request...' });

    const errorMsg = intl.formatMessage({ id: 'admin.admin_user.admin_user_invite_customer.form.response_message.error', defaultMessage: 'Form data not valid. Please see below.' });

    const mutationName = 'adminUserInviteCustomer';

    if (processing) {
      FlashesStore.flash(FlashesStore.INFO, infoMsg, mutationName);
      return;
    }

    if (!this.isValid()) {
      FlashesStore.flash(FlashesStore.ERROR, errorMsg, mutationName);
      return;
    }

    const {
      email, familyName, givenName,
    } = this.state;

    const input = {
      email, familyName, givenName,
    };

    handleInvite(input);
  };

  handleEmailChange = (event) => {
    const { value: email } = event.target;
    const emailValid = isEmail(email);

    this.setState({ email, emailValid });
  };

  handleFamilyNameChange = (event) => {
    const { value } = event.target;

    this.setState({ familyName: value });
  };

  handleGivenNameChange = (event) => {
    const { value } = event.target;

    this.setState({ givenName: value });
  };

  render() {
    const { processing, intl } = this.props;

    const {
      email, familyName, givenName, emailValid,
    } = this.state;

    return (
      <Form onSubmit={this.handleSubmit} disabled={processing}>
        <Card>
          <CardHeader tag="h2">
            {intl.formatMessage({ id: 'admin.admin_user.admin_user_invite_customer.form.email.heading', defaultMessage: 'Invite a new customer to the Enosi platform' })}
          </CardHeader>
          <CardBody>
            <FormGroup>
              <Label for="email">{intl.formatMessage({ id: 'admin.admin_user.admin_user_invite_customer.form.email.label', defaultMessage: 'Email' })}</Label>
              <Input required type="email" name="email" id="email" value={email} onChange={this.handleEmailChange} disabled={processing} valid={emailValid} invalid={emailValid !== null && !emailValid} />
              <FormFeedback>{intl.formatMessage({ id: 'admin.admin_user.admin_user_invite_customer.form.invalid_email.feedback', defaultMessage: 'Not a valid email' })}</FormFeedback>
              <FormText>
                {intl.formatMessage({ id: 'admin.admin_user.admin_user_invite_customer.form.email.hint', defaultMessage: 'This email address will be used by the customer user to log onto the Enosi platform' })}
              </FormText>
            </FormGroup>
            <FormGroup>
              <Label for="givenName">{intl.formatMessage({ id: 'admin.admin_user.admin_user_invite_customer.form.given_name.label', defaultMessage: 'Given name' })}</Label>
              <Input type="text" name="givenName" id="givenName" value={givenName} onChange={this.handleGivenNameChange} disabled={processing} />
            </FormGroup>
            <FormGroup>
              <Label for="familyName">{intl.formatMessage({ id: 'admin.admin_user.admin_user_invite_customer.form.family_name.label', defaultMessage: 'Family name' })}</Label>
              <Input type="text" name="familyName" id="familyName" value={familyName} onChange={this.handleFamilyNameChange} disabled={processing} />
            </FormGroup>
          </CardBody>
          <CardFooter>
            <Button disabled={processing}>{intl.formatMessage({ id: 'admin.admin_user.admin_user_invite_customer.form.submit.label', defaultMessage: 'Send invitation' })}</Button>
          </CardFooter>
        </Card>
      </Form>
    );
  }
}

AdminUserInviteCustomerForm.propTypes = {
  handleInvite: PropTypes.func,
  intl: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  processing: PropTypes.bool,
};

AdminUserInviteCustomerForm.defaultProps = {
  handleInvite: null,
  processing: false,
};

export default injectIntl(AdminUserInviteCustomerForm);

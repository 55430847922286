/**
 * Determines the appropriate date format to use for displaying the start date of a date range.
 * The format is chosen based on the difference between the start and end dates.
 * @param {object} s - The start date object.
 * @param {object} f - The end date object.
 * @returns {string} The appropriate date format to use for the start date.
 */
const getStartFormat = (s, f) => {
  // Check if the start and finish dates are in the same month of the same year.
  //  If so, use the day of the month.
  if (s?.month === f?.month && s?.year === f?.year) return 'd';
  // If the start and finish dates are in the same year but different month,
  // display the month as well as the day of the month.
  if (s?.year === f?.year) return 'MMM d';
  // Otherwise, use localized date with abbreviated month.
  return 'DD';
};

export default getStartFormat;

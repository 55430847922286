// @ts-nocheck
import PropTypes from 'prop-types';
import React from 'react';
import { Helmet } from 'react-helmet-async';
import { FormattedMessage, injectIntl } from 'react-intl';
import {
  Button, Form, FormFeedback, FormGroup, FormText, Input, Label,
} from 'reactstrap';
import isEmail from 'validator/es/lib/isEmail';

import RegisterConfirmMutation from 'src/mutations/RegisterConfirmMutation';
import FlashesStore from 'src/stores/FlashesStore';
import isPassword from 'src/validators/isPassword';

import AuthContainer, { AUTH_TYPE_REGISTER } from '../AuthContainer';

class AuthRegister extends React.Component {
  static mutationName = 'authRegister';

  constructor(props) {
    super(props);

    this.state = {
      email: '',
      emailValid: null,
      password: '',
      passwordValid: null,
      confirmPassword: '',
      confirmPasswordValid: null,
      processing: false,
    };
  }

  handleEmailChange = (e) => {
    const { value: email } = e.target;
    const emailValid = isEmail(email);

    this.setState({ email, emailValid });
  };

  handlePasswordChange = (e) => {
    const { value: password } = e.target;
    const passwordValid = isPassword(password);

    this.setState({ password, passwordValid });
  };

  handleConfirmPasswordChange = (e) => {
    const { value: confirmPassword } = e.target;
    const { password } = this.state;
    const confirmPasswordValid = confirmPassword === password;

    this.setState({ confirmPassword, confirmPasswordValid });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    const { email, password, processing } = this.state;
    const { intl } = this.props;
    FlashesStore.reset();

    if (processing) {
      const msgInprogress = intl.formatMessage({
        id: 'auth.auth_register.form.state.invalid_still_processing',
        defaultMessage: 'We are still processing your request...',
      });
      FlashesStore.flash(FlashesStore.INFO, msgInprogress, AuthRegister.mutationName);
      return;
    }

    if (!this.isValid()) {
      const msgInvalidData = intl.formatMessage({ id: 'auth.auth_register.form.state.invalid_data', defaultMessage: 'Form data not valid. Please see below.' });
      FlashesStore.flash(FlashesStore.ERROR, msgInvalidData, AuthRegister.mutationName);
      return;
    }

    this.setState({ processing: true });

    const { match } = this.props;
    const { params } = match;
    const { token } = params;

    RegisterConfirmMutation(
      {
        email,
        password,
        verificationCode: token,
      },
      this.handleSubmitSuccess,
      this.handleSubmitFailure,
    );
  };

  // eslint-disable-next-line no-unused-vars
  handleSubmitSuccess = (_response) => {
    this.setState({ processing: false });

    const { intl } = this.props;
    const msgSuccess = intl.formatMessage({
      id: 'auth.auth_register.form.response_message.success',
      defaultMessage: 'Registration was successful. Please confirm by logging in with your email and password.',
    });

    FlashesStore.flash(
      FlashesStore.SUCCESS,
      msgSuccess,
      AuthRegister.mutationName,
    );
    const { router } = this.props;
    router.push('/login');
  };

  handleSubmitFailure = (error) => {
    this.setState({ processing: false });

    FlashesStore.flash(FlashesStore.ERROR, error.message, AuthRegister.mutationName);
  };

  isValid = () => {
    const { emailValid, passwordValid, confirmPasswordValid } = this.state;

    return emailValid && passwordValid && confirmPasswordValid;
  };

  render() {
    const {
      email, emailValid, password, passwordValid,
      confirmPassword, confirmPasswordValid, processing,
    } = this.state;

    const { intl } = this.props;
    const pageTitle = intl.formatMessage({ id: 'auth.auth_register.page.title', defaultMessage: 'Enosi - register to get started' });
    const emailLabel = intl.formatMessage({ id: 'auth.auth_register.form.email.label', defaultMessage: 'Your account’s email address' });
    const emailPlaceholder = intl.formatMessage({ id: 'auth.auth_register.form.email.placeholder', defaultMessage: 'Your account’s email address' });
    const emailFeedbackInvalid = intl.formatMessage({ id: 'auth.auth_register.form.email.form_feedback.invalid', defaultMessage: 'Invalid email address' });
    const emailHelpText = intl.formatMessage({ id: 'auth.auth_register.form.email.help_text', defaultMessage: 'Email address associated with your Enosi account' });
    const passwordLabel = intl.formatMessage({ id: 'auth.auth_register.form.password.label', defaultMessage: 'Password' });
    const passwordPlaceholder = intl.formatMessage({ id: 'auth.auth_register.form.password.placeholder', defaultMessage: 'Enter password' });
    const passwordConfirmationLabel = intl.formatMessage({ id: 'auth.auth_register.form.password_confirmation.label', defaultMessage: 'Password confirmation' });
    const passwordConfirmationPlaceholder = intl.formatMessage({ id: 'auth.auth_register.form.password_confirmation.placeholder', defaultMessage: 'Confirm password' });
    const passwordConfirmationFeedbackInvalid = intl.formatMessage({ id: 'auth.auth_register.form.password_confirmation.feedback.invalid', defaultMessage: 'Passwords must match' });
    const passwordConfirmationHelpText = intl.formatMessage({ id: 'auth.auth_register.form.password_confirmation.help_text', defaultMessage: 'Confirm the password you are setting' });
    const submitLabel = intl.formatMessage({ id: 'auth.auth_register.form.submit.label', defaultMessage: 'Register' });

    // Terms and conditions.
    const termsAndConditionsLinkLabel = intl.formatMessage({ id: 'auth.auth_register.form.terms_and_conditions.link.label', defaultMessage: 'Powertracer Terms and Conditions' });
    const termsAndConditionsLinkUrl = intl.formatMessage({ id: 'auth.auth_register.form.terms_and_conditions.link.url', defaultMessage: 'https://enosi.energy/terms/powertracer' });
    const termsAndConditionsLink = (
      <a href={termsAndConditionsLinkUrl}>{termsAndConditionsLinkLabel}</a>
    );

    return (
      <>
        <Helmet>
          <meta charSet="utf-8" />
          <title>{pageTitle}</title>
        </Helmet>

        <AuthContainer type={AUTH_TYPE_REGISTER} title="Registration">
          <Form onSubmit={this.handleSubmit}>
            <FormGroup>
              <Label htmlFor="email">{emailLabel}</Label>
              <Input
                type="email"
                id="email"
                aria-describedby="email"
                placeholder={emailPlaceholder}
                onChange={this.handleEmailChange}
                value={email}
                disabled={processing}
                valid={emailValid}
                invalid={emailValid !== null && !emailValid}
              />
              <FormFeedback>{emailFeedbackInvalid}</FormFeedback>
              <FormText>{emailHelpText}</FormText>
            </FormGroup>
            <FormGroup>
              <Label htmlFor="formPassword">{passwordLabel}</Label>
              <Input
                type="password"
                id="formPassword"
                aria-describedby="password"
                placeholder={passwordPlaceholder}
                onChange={this.handlePasswordChange}
                value={password}
                valid={passwordValid}
                invalid={passwordValid !== null && !passwordValid}
                disabled={processing}
              />
              <FormFeedback>
                <FormattedMessage
                  id="auth.auth_register.form.password.form_feedback.invalid"
                  defaultMessage="Password must be at least 8 characters long, with uppercase, lowercase, number and one of:{specialCharacters}"
                  values={{
                    specialCharacters:
                      // eslint-disable-next-line react/jsx-indent
                      <>
                        <br />
                        <code className="code">
                          {'^ $ * . [ ] { } ( ) ? " ! @ # % & / \\ , < > \' : ; | _ ~ `'}
                        </code>
                      </>,
                  }}
                />
              </FormFeedback>
            </FormGroup>
            <FormGroup>
              <Label htmlFor="formPasswordConfirmation">
                {passwordConfirmationLabel}
              </Label>
              <Input
                type="password"
                id="formPasswordConfirmation"
                aria-describedby="password"
                placeholder={passwordConfirmationPlaceholder}
                onChange={this.handleConfirmPasswordChange}
                value={confirmPassword}
                valid={confirmPasswordValid}
                invalid={confirmPasswordValid !== null && !confirmPasswordValid}
                disabled={processing}
              />
              <FormFeedback>{passwordConfirmationFeedbackInvalid}</FormFeedback>
              <FormText>{passwordConfirmationHelpText}</FormText>
            </FormGroup>
            <p>
              <small>
                <FormattedMessage
                  id="auth.auth_register.form.terms_and_conditions.text"
                  defaultMessage="By registering your account you accept the {termsAndConditionsLink}"
                  values={{ termsAndConditionsLink }}
                />
              </small>
            </p>
            <Button type="submit" color="primary" disabled={processing}>
              {submitLabel}
            </Button>
          </Form>
        </AuthContainer>
      </>
    );
  }
}

AuthRegister.propTypes = {
  intl: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  match: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  router: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
};

export default injectIntl(AuthRegister);
